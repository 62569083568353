<template>
  <app-modal
    :valid="!!name"
    :title="title"
    v-model="open"
    width="500"
    :confirm-label="isEdit ? 'Update' : 'Save'"
    @confirmed="submit"
  >
    <v-card flat color="transparent">
      <v-card-text>
        <label class="xfont-bold xleading-[1em]">Region Name *</label>
        <app-input
          type="text"
          :rules="[requiredRule()]"
          v-model="name"
          placeholder="Enter region name"
          class="mb-2"
        ></app-input>
      </v-card-text>
    </v-card>
  </app-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object, default: undefined },
  },
  data() {
    return { open: false, name: null, remark: null };
  },
  computed: {
    ...mapGetters(["user"]),
    payload() {
      return {
        name: this.name,
      };
    },
    isEdit() {
      return !!this.item;
    },
    title() {
      return this.isEdit ? "Update Region" : "New Region";
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val;
        if (val && !this.isEdit) this.name = null;
      },
      immediate: true,
    },
    open: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    item: {
      handler: function (val) {
        this.name = val ? val.name : null;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceStatistics"]),
    submit() {
      if (this.isEdit) this.handleUpdate();
      else this.handleSave();
    },
    handleSave() {
      this.$axios.post(`api/regions`, this.payload).then(({ data }) => {
        this.$emit("saved", data);
        this.open = false;
        this.appToast("New region created", "success");
        this.fetchWorkspaceStatistics(this.user.team_id);
      });
    },
    handleUpdate() {
      this.$axios
        .put(`api/regions/${this.item.id}`, this.payload)
        .then(({ data }) => {
          this.$emit("updated", data);
          this.open = false;
          this.appToast("region updated", "success");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
